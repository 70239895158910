<template>
	<div class="view pa24">
		<div style="font-size: 26px;font-weight: bold;margin-bottom: 30px;">收银台 <span
				style="font-size: 16px;color: #51CDCB;margin-bottom: 15px;font-weight: normal;">可用余额：500</span></div>
		<el-form style="width: 30%;" :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px">
			<el-form-item label="商户订单号" prop="order">
				<el-input clearable v-model="ruleForm.order" placeholder="请输入商户订单号"></el-input>
			</el-form-item>
			<el-form-item label="订单金额" prop="money">
				<el-input clearable v-model="ruleForm.money" placeholder="请输入订单金额"></el-input>
			</el-form-item>
			<!-- <el-form-item label="通道" prop="tongdao">
				<el-select style="width: 100%;" clearable v-model="ruleForm.tongdao" placeholder="请选择通道">
					<el-option v-for="(item,index) in fashiList" :label="item.name" :value="item.index">
					</el-option>
				</el-select>
			</el-form-item> -->
			<el-form-item label="异步通知地址" prop="address1">
				<el-input clearable v-model="ruleForm.address1" placeholder="请输入异步通知地址"></el-input>
			</el-form-item>
			<el-form-item label="同步跳转地址" prop="address2">
				<el-input clearable v-model="ruleForm.address2" placeholder="请输入同步跳转地址"></el-input>
			</el-form-item>
			<el-form-item label="附加" prop="fujia">
				<el-input clearable v-model="ruleForm.fujia" placeholder="请输入谷歌验证码"></el-input>
			</el-form-item>
			<el-form-item label="打款人" prop="person">
				<el-input clearable v-model="ruleForm.person" placeholder="请输入打款人"></el-input>
			</el-form-item>
			<el-form-item label="支付密码" prop="password">
				<el-input clearable v-model="ruleForm.password" placeholder="请输入支付密码"></el-input>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" @click="determine">提 交</el-button>
			</el-form-item>
		</el-form>
	</div>
</template>

<script>
	import {
		mapState
	} from 'vuex';
	export default {
		name: '',
		data() {
			return {
				options: [],
				ruleForm: {
					order: "",
					money: "",
					tongdao: '',
					address1: '',
					address2: '',
					fujia: '',
					person: '',
					password: '',
				},
				fashiList: [],
				rules: {
					money: [{
						required: true,
						message: "请输入金额",
						trigger: "blur"
					}],
					tongdao: [{
						required: true,
						message: "请选择通道",
						trigger: "blur"
					}],
				},
			};
		},
		mounted() {
			// this.getCGSList()
		},

		methods: {
			async getCGSList() {
				let ajax = {
					acountId: localStorage.getItem('acountId')
				}
				getOfficeLogin(ajax).then((res) => {
					if (res.code !== 200) {
						return;
					}
					this.optionsCGS = res.data
				})
			},
			determine() {
				this.$refs.ruleForm.validate(async (valid) => {
					if (valid) {
						try {
							let data = {
								oldPassword: this.ruleForm.oldPassword,
								newPassword: this.ruleForm.newPassword,
								qrPassword: this.ruleForm.qrPassword,
								newPayPassword: this.ruleForm.newPayPassword,
								qrnewPayPassword: this.ruleForm.qrnewPayPassword,
								code: this.ruleForm.code,
							};
							this.$message({
								message: "正在保存",
							});
							if (this.editUpdate) {
								data.windowsConfigId = this.windowsConfigId
								await upWindowsConfig(data);
							} else {
								await addWindowsConfig(data);
							}
							this.$message.closeAll();
							this.$message({
								message: "保存成功",
								type: "success",
							});
							await this.queryPage();
						} catch (error) {
							this.$message.closeAll();
							this.$message({
								message: error.message,
								type: "error",
							});
						}
					} else {
						console.log("error submit!!");
						return false;
					}
				});
			},
		},
	};
</script>

<style lang="scss" scoped>
	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 78px;
		height: 78px;
		//line-height: 175px;
		text-align: center;
	}

	/deep/ .avatar-coverImg .el-upload--text {
		width: 100px;
		height: 100px;
	}

	/deep/ .avatar-coverImg .avatar-uploader-icon {
		line-height: 100px;
	}

	.select-goods-list {
		margin-bottom: 20px;
		border-radius: 40px;
		box-sizing: border-box;
		padding: 0 20px;
		background: #ffffff;
		transition: all 0.4s;
	}

	.select-goods-list:hover {
		background: #f5f5f6;
		transition: all 0.4s;
		cursor: pointer;
	}

	/deep/ .disabled .el-upload--picture-card {
		display: none;
	}
</style>